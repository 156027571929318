import React from 'react';
import { Link } from 'react-router-dom';
import Skeleton from '../Skeleton';
import FavoriteButton from './FavoriteButton';

const MovieOverview = ({
	item,
	credits,
	formatReleaseDate,
	isFavorite,
	handleAddToFavorites,
	handleRemoveFromFavorites,
	type,
	isLoading,
	handleShowPlayer,
}) => {
	if (isLoading) {
		return <Skeleton />;
	}

	const releaseDate = item.release_date || item.first_air_date;

	const getEmoji = rating => {
		if (rating >= 8) return '🌟';
		if (rating >= 7) return '🎉';
		if (rating >= 6) return '😊';
		if (rating >= 5) return '😐';
		if (rating >= 4) return '😕';
		if (rating >= 3) return '😢';
		return '💩';
	};

	const director = credits.crew.find(
		crew => crew.known_for_department === 'Directing'
	);

	const placeholderImage =
		'https://www.themoviedb.org/assets/2/v4/glyphicons/basic/glyphicons-basic-4-user-grey-d8fe957375e70239d6abdd549fd7568c89281b2179b5f4470e2e12895792dfa5.svg'; // Путь к заглушке

	return (
		<div className='row mb-4'>
			<div className='col-12 col-sm-4 col-md-3 mb-4 mb-md-0'>
				<div className='sticky-top position-relative'>
					<div className='backrop-image'>
						<img
							className='img-fluid'
							src={`https://image.tmdb.org/t/p/w300${item.poster_path}`}
							alt='Backdrop'
						/>
					</div>
					<img
						className='img-fluid rounded-3'
						src={`https://image.tmdb.org/t/p/w500${item.poster_path}`}
						alt={item.title || item.name}
					/>
					<div className='d-flex gap-2 mt-2'>
						<FavoriteButton
							isFavorite={isFavorite}
							onAdd={handleAddToFavorites}
							onRemove={handleRemoveFromFavorites}
						/>
					</div>
				</div>
			</div>

			<div className='col-12 col-sm'>
				<div className='mb-4'>
					<div className='d-flex align-items-center gap-3'>
						<h1 className='movie-title display-5 fw-bold mb-0 me-auto'>
							{item.title || item.name}
						</h1>
						{item.vote_average > 0 && (
							<div className='text-warning d-flex gap-2 fs-2'>
								<span>{item.vote_average.toFixed(1)}</span>
								<span>{getEmoji(item.vote_average)}</span>
							</div>
						)}
					</div>

					<div className='d-flex flex-wrap align-items-center gap-2'>
						{type === 'tv' && item.original_name && (
							<span className='movie-title-original fs-5 fw-bold'>
								{item.original_name}
							</span>
						)}
						{type === 'movie' && item.original_title && (
							<span className='movie-title-original fs-5 fw-bold'>
								{item.original_title}
							</span>
						)}
					</div>
				</div>

				<div className='d-block mb-4'>
					<h5 className='fs-4 mb-3 fw-bold'>
						{item.tagline ? item.tagline.replace(/^[«']|[»']$/g, '') : ''}
					</h5>
					<p>{item.overview || 'Нет описания'}</p>

					<div className='d-flex align-items-center flex-wrap gap-2 text-muted small mb-3'>
						<span className='text-muted'>{formatReleaseDate(releaseDate)}</span>
						<span>|</span>
						{item.runtime && (
							<span className='fw-medium'>
								{Math.floor(item.runtime / 60)} ч. {item.runtime % 60} мин.
							</span>
						)}
					</div>
					{item.genres && item.genres.length > 0
						? item.genres.map(genre => (
								<Link
									to={`/${type === 'movie' ? 'movies' : 'tv-shows'}?genre=${genre.id}`}
									key={genre.id}
									className='badge-genre me-2'
								>
									{genre.name}
								</Link>
							))
						: 'Не указано'}
				</div>

				<div className='row'>
					<div className='col-12 col-sm-6'>
						<div className='d-flex flex-column'>
							<div className='mb-2 fw-medium'>Актеры:</div>
							{credits.cast && credits.cast.length > 0 ? (
								<ul
									className='d-flex flex-wrap gap-2'
									style={{ listStyle: 'none', padding: 0 }}
								>
									{credits.cast.slice(0, 10).map(actor => (
										<li key={actor.id}>
											<Link to={`/actor/${actor.id}`}>
												<img
													src={
														actor.profile_path
															? `https://image.tmdb.org/t/p/w92${actor.profile_path}`
															: placeholderImage
													}
													alt={actor.name}
													className='movie-actor-photo'
												/>
											</Link>
										</li>
									))}
								</ul>
							) : (
								<span>Информация отсутствует</span>
							)}
						</div>
					</div>
					{director && (
						<div className='col-12 col-sm-6'>
							<div className='mb-2 fw-medium'>Режиссер:</div>
							<Link to={`/director/${director.id}`}>
								<img
									src={
										director.profile_path
											? `https://image.tmdb.org/t/p/w92${director.profile_path}`
											: placeholderImage
									}
									alt={director.name}
									className='movie-actor-photo'
								/>
							</Link>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default MovieOverview;

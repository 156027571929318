import axios from 'axios';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import MovieCard from '../components/MovieCard';

const API_KEY = '8c25628d17af8bc23ca51c3561b5b636';

const CatalogMovies = () => {
	const [movies, setMovies] = useState([]);
	const [genres, setGenres] = useState([]);
	const [countries, setCountries] = useState([]);
	const [selectedGenres, setSelectedGenres] = useState([]);
	const [selectedCountry, setSelectedCountry] = useState([]);
	const [yearsRange, setYearsRange] = useState([
		1991,
		new Date().getFullYear(),
	]);
	const [page, setPage] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');

	const navigate = useNavigate();
	const { genreId } = useParams();
	const location = useLocation();

	const genreFromUrl = new URLSearchParams(location.search).get('genre');

	const fetchGenresAndCountries = useCallback(async () => {
		try {
			const [genresResponse, countriesResponse] = await Promise.all([
				axios.get(
					`https://api.themoviedb.org/3/genre/movie/list?api_key=${API_KEY}&language=ru`
				),
				axios.get(
					`https://api.themoviedb.org/3/configuration/countries?api_key=${API_KEY}`
				),
			]);
			setGenres(
				genresResponse.data.genres.map(genre => ({
					value: genre.id,
					label: genre.name,
				}))
			);
			setCountries(
				countriesResponse.data.map(country => ({
					value: country.iso_3166_1,
					label: country.english_name,
				}))
			);
		} catch (error) {
			console.error('Ошибка при загрузке данных:', error);
			setError('Не удалось загрузить данные.');
		}
	}, []);

	useEffect(() => {
		fetchGenresAndCountries();
	}, [fetchGenresAndCountries]);

	useEffect(() => {
		if (genreId || genreFromUrl) {
			const genreValue = parseInt(genreId || genreFromUrl);
			if (!isNaN(genreValue)) {
				const selectedGenre = genres.find(g => g.value === genreValue);
				if (selectedGenre) setSelectedGenres([selectedGenre]);
			}
		}
	}, [genreId, genreFromUrl, genres]);

	const fetchMovies = useCallback(async () => {
		setIsLoading(true);
		setError('');
		try {
			const genresIds = selectedGenres.map(genre => genre.value).join(',');
			const countriesIds = selectedCountry
				.map(country => country.value)
				.join(',');

			const response = await axios.get(
				`https://api.themoviedb.org/3/discover/movie?api_key=${API_KEY}&language=ru&sort_by=popularity.desc&page=${page}&with_genres=${genresIds}&primary_release_date.gte=${yearsRange[0]}-01-01&primary_release_date.lte=${yearsRange[1]}-12-31&with_origin_country=${countriesIds}`
			);
			setMovies(prevMovies =>
				page === 1
					? response.data.results
					: [...prevMovies, ...response.data.results]
			);
		} catch (error) {
			console.error('Ошибка при загрузке фильмов:', error);
			setError('Не удалось загрузить фильмы.');
		} finally {
			setIsLoading(false);
		}
	}, [page, selectedGenres, yearsRange, selectedCountry]);

	useEffect(() => {
		fetchMovies();
	}, [fetchMovies]);

	const handleApplyFilters = () => {
		setPage(1);
		fetchMovies(); // Fetch movies with the current selected filters
		navigate({
			pathname: '/movies',
			search: new URLSearchParams({
				genre: selectedGenres.map(genre => genre.value).join(','),
				startYear: yearsRange[0],
				endYear: yearsRange[1],
				country: selectedCountry.map(country => country.value).join(','),
			}).toString(),
		});
	};

	const handleScroll = () => {
		if (
			window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 &&
			!isLoading
		) {
			setPage(prevPage => prevPage + 1);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [isLoading]);

	const years = Array.from({ length: 50 }, (_, index) => {
		const year = new Date().getFullYear() - index;
		return year;
	});

	return (
		<div className='container pb-5'>
			<h1 className='h2 mb-4'>Фильмы</h1>
			<div className='filter-section mb-2'>
				<div className='row'>
					<div className='col-12 col-sm-6'>
						<Select
							isMulti
							options={genres}
							value={selectedGenres}
							onChange={setSelectedGenres}
							className='react-select-container w-100'
							classNamePrefix='react-select'
							placeholder='Выберите жанры'
						/>
					</div>
					<div className='col-12 col-sm-6'>
						<Select
							isMulti
							options={countries}
							value={selectedCountry}
							onChange={setSelectedCountry}
							className='react-select-container w-100'
							classNamePrefix='react-select'
							placeholder='Выберите страны'
						/>
					</div>
					<div className='col-12 mt-2'>
						<div className='p-3 rounded bg-dark'>
							<h5 className='fs-6 mb-2'>Выберите диапазон годов:</h5>
							<Slider
								range
								min={years[years.length - 1]} // Минимальный год
								max={years[0]} // Максимальный год
								value={yearsRange}
								onChange={setYearsRange}
							/>
							<div className='d-flex justify-content-between mt-1'>
								<span className='fw-medium'>{yearsRange[0]}</span>
								<span className='fw-medium'>{yearsRange[1]}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<button className='btn btn-primary mb-5' onClick={handleApplyFilters}>
				Применить фильтры
			</button>
			{error && <div className='text-danger mb-4'>{error}</div>}
			<div className='row'>
				{movies.map(movie => (
					<MovieCard key={movie.id} movie={movie} />
				))}
			</div>
			{isLoading && <div className='text-center p-4'>Загрузка...</div>}
		</div>
	);
};

export default CatalogMovies;

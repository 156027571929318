import React, { useState } from 'react';

const API_KEY = '8c25628d17af8bc23ca51c3561b5b636';

const LoginPage = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const handleLogin = async e => {
		e.preventDefault();
		setIsLoading(true);
		setError('');

		try {
			// Шаг 1: Создание сессии
			const sessionResponse = await fetch(
				`https://api.themoviedb.org/3/authentication/token/new?api_key=${API_KEY}`
			);
			const sessionData = await sessionResponse.json();
			const requestToken = sessionData.request_token;

			// Шаг 2: Аутентификация пользователя
			const authResponse = await fetch(
				`https://api.themoviedb.org/3/authentication/token/validate_with_login?api_key=${API_KEY}`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						username,
						password,
						request_token: requestToken,
					}),
				}
			);

			const authData = await authResponse.json();

			if (authData.success) {
				// Шаг 3: Создание сессии
				const sessionIdResponse = await fetch(
					`https://api.themoviedb.org/3/authentication/session/new?api_key=${API_KEY}`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							request_token: authData.request_token,
						}),
					}
				);

				const sessionIdData = await sessionIdResponse.json();

				if (sessionIdData.success) {
					// Сохраните session_id в локальное хранилище
					localStorage.setItem('tmdb_session_id', sessionIdData.session_id);
					// Перенаправление на страницу пользователя или домой
					window.location.href = '/user'; // Перенаправление на страницу пользователя
				} else {
					setError('Не удалось создать сессию.');
				}
			} else {
				setError('Ошибка аутентификации. Проверьте логин и пароль.');
			}
		} catch (err) {
			console.error('Ошибка при входе:', err);
			setError('Произошла ошибка. Попробуйте еще раз.');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className='container mt-5'>
			<h2>Вход в систему</h2>
			{error && <div className='alert alert-danger'>{error}</div>}
			<form onSubmit={handleLogin}>
				<div className='mb-3'>
					<label className='form-label'>Логин</label>
					<input
						type='text'
						className='form-control'
						value={username}
						onChange={e => setUsername(e.target.value)}
						required
					/>
				</div>
				<div className='mb-3'>
					<label className='form-label'>Пароль</label>
					<input
						type='password'
						className='form-control'
						value={password}
						onChange={e => setPassword(e.target.value)}
						required
					/>
				</div>
				<button type='submit' className='btn btn-primary' disabled={isLoading}>
					{isLoading ? 'Загрузка...' : 'Войти'}
				</button>
			</form>
		</div>
	);
};

export default LoginPage;

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import MovieCard from '../components/MovieCard';

const API_KEY = '8c25628d17af8bc23ca51c3561b5b636';

const Home = () => {
	const [items, setItems] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState('');

	useEffect(() => {
		const fetchMoviesAndShows = async () => {
			try {
				// Проверяем наличие кэшированных данных
				const cachedData = localStorage.getItem('trendingItems');
				if (cachedData) {
					setItems(JSON.parse(cachedData));
					setIsLoading(false);
					return;
				}

				const [moviesResponse, showsResponse] = await Promise.all([
					axios.get(
						`https://api.themoviedb.org/3/trending/movie/week?api_key=${API_KEY}&language=ru`
					),
					axios.get(
						`https://api.themoviedb.org/3/trending/tv/week?api_key=${API_KEY}&language=ru`
					),
				]);

				const movies = moviesResponse.data.results.map(item => ({
					...item,
					media_type: 'movie',
				}));
				const shows = showsResponse.data.results.map(item => ({
					...item,
					media_type: 'tv',
				}));

				const combined = [...movies, ...shows];

				const sortedItems = combined.sort((a, b) => {
					const dateA = a.release_date || a.first_air_date;
					const dateB = b.release_date || b.first_air_date;
					return new Date(dateB) - new Date(dateA);
				});

				// Сохраняем данные в localStorage
				localStorage.setItem('trendingItems', JSON.stringify(sortedItems));
				setItems(sortedItems);
			} catch (error) {
				console.error('Ошибка при загрузке данных:', error);
				setError('Не удалось загрузить данные. Попробуйте позже.');
			} finally {
				setIsLoading(false);
			}
		};

		fetchMoviesAndShows();
	}, []);

	if (isLoading) {
		return <div className='text-center'>Загрузка...</div>;
	}

	if (error) {
		return <div className='text-center text-danger'>{error}</div>;
	}

	return (
		<div className='container mb-5'>
			<h1 className='h2 mb-4'>Топ недели</h1>
			<div className='row'>
				{items.map(item => (
					<MovieCard key={item.id} movie={item} />
				))}
			</div>
		</div>
	);
};

export default Home;

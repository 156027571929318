import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

const ToastNotification = ({ showToast, toastMessage, onClose }) => {
	return (
		<ToastContainer className='position-fixed bottom-0 end-0 p-3'>
			<Toast
				className='text-bg-secondary'
				onClose={onClose}
				show={showToast}
				delay={3000}
				autohide
			>
				<Toast.Body>{toastMessage}</Toast.Body>
			</Toast>
		</ToastContainer>
	);
};

export default ToastNotification;

import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from './logo.svg';

const API_KEY = '8c25628d17af8bc23ca51c3561b5b636';
const cache = {};

const Header = ({ onSearch }) => {
	const [query, setQuery] = useState('');
	const [results, setResults] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [visibleCount, setVisibleCount] = useState(5);
	const navigate = useNavigate();
	const location = useLocation();

	const fetchSearchResults = useCallback(async () => {
		if (!query) {
			setResults([]);
			return;
		}

		setIsLoading(true);
		if (cache[query]) {
			setResults(cache[query]);
			setIsLoading(false);
			return;
		}

		try {
			const response = await fetch(
				`https://api.themoviedb.org/3/search/multi?api_key=${API_KEY}&language=ru&query=${query}`
			);
			const data = await response.json();
			cache[query] = data.results;
			setResults(data.results);
		} catch (error) {
			console.error('Ошибка при поиске:', error);
		} finally {
			setIsLoading(false);
		}
	}, [query]);

	useEffect(() => {
		const delayDebounceFn = setTimeout(fetchSearchResults, 300);
		return () => clearTimeout(delayDebounceFn);
	}, [fetchSearchResults]);

	const handleSearch = e => {
		e.preventDefault();
		if (query.trim()) {
			onSearch(query);
			setVisibleCount(5);
		}
	};

	const handleLogout = () => {
		localStorage.removeItem('tmdb_session_id');
		navigate('/');
	};

	const isAuthenticated = !!localStorage.getItem('tmdb_session_id');

	return (
		<header className='header text-white p-3 mb-4'>
			<div className='container d-flex flex-wrap gap-2 gap-md-4 align-items-center'>
				<Link
					to='/'
					className='h2 m-0 text-decoration-none text-white d-none d-md-block'
				>
					<img src={logo} alt='Логотип' height='32' />
				</Link>
				<nav className='nav-mobile d-flex gap-1'>
					{['/', '/movies', '/tv-shows'].map(path => (
						<Link
							key={path}
							to={path}
							className={`btn btn-secondary ${location.pathname === path ? 'active' : ''}`}
						>
							{path === '/'
								? 'Топ недели'
								: path === '/movies'
									? 'Фильмы'
									: 'Сериалы'}
						</Link>
					))}
				</nav>
				<form onSubmit={handleSearch} className='flex-fill d-flex gap-2'>
					<input
						type='text'
						className='form-control'
						placeholder='Поиск фильмов или сериалов'
						value={query}
						onChange={e => setQuery(e.target.value)}
					/>
					{results.length > 0 && (
						<div className='dropdown-search dropdown-menu show'>
							{isLoading ? (
								<div className='dropdown-item'>Загрузка...</div>
							) : (
								results.slice(0, visibleCount).map(result => (
									<Link
										key={result.id}
										to={`/${result.media_type === 'tv' ? 'tv' : 'movie'}/${result.id}`}
										className='dropdown-item'
										onClick={() => setQuery('')}
									>
										{result.title || result.name}
										<span className='text-muted'>
											{result.release_date || result.first_air_date
												? ` (${(result.release_date || result.first_air_date).split('-')[0]})`
												: ' (Неизвестно)'}
										</span>
									</Link>
								))
							)}
							{visibleCount < results.length && (
								<div
									className='dropdown-item text-center'
									onClick={() => setVisibleCount(prev => prev + 5)}
									style={{ cursor: 'pointer' }}
								>
									Показать еще
								</div>
							)}
						</div>
					)}
				</form>
				<Link
					to={isAuthenticated ? '/user' : '/login'}
					className='btn btn-primary'
				>
					{isAuthenticated ? 'Профиль' : 'Вход'}
				</Link>
			</div>
		</header>
	);
};

export default Header;

import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';

const API_KEY = '8c25628d17af8bc23ca51c3561b5b636';

const EpisodeList = ({ showId }) => {
	const [episodes, setEpisodes] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const [error, setError] = useState(null); // Для отслеживания ошибок

	useEffect(() => {
		const fetchEpisodes = async () => {
			setIsLoading(true);
			setError(null); // Очистить ошибку перед новым запросом
			try {
				const seasonResponse = await fetch(
					`https://api.themoviedb.org/3/tv/${showId}?api_key=${API_KEY}&language=ru`
				);
				const seasonData = await seasonResponse.json();

				if (
					!seasonData ||
					!seasonData.seasons ||
					seasonData.seasons.length === 0
				) {
					throw new Error('Данные о сезонах отсутствуют.');
				}

				const lastSeason = seasonData.seasons[seasonData.seasons.length - 1];

				if (!lastSeason) {
					throw new Error('Нет информации о последнем сезоне.');
				}

				const episodesResponse = await fetch(
					`https://api.themoviedb.org/3/tv/${showId}/season/${lastSeason.season_number}?api_key=${API_KEY}&language=ru`
				);
				const episodesData = await episodesResponse.json();

				if (
					!episodesData ||
					!episodesData.episodes ||
					episodesData.episodes.length === 0
				) {
					throw new Error('Нет информации о сериях.');
				}

				setEpisodes(episodesData.episodes);
			} catch (error) {
				console.error('Ошибка при загрузке эпизодов:', error);
				setError(error.message || 'Не удалось загрузить данные.');
			} finally {
				setIsLoading(false);
			}
		};

		fetchEpisodes();
	}, [showId]);

	if (isLoading) return <div>Загрузка эпизодов...</div>;
	if (error) return <div className='text-danger'>{error}</div>;
	if (!episodes || episodes.length === 0) return <div>Эпизоды не найдены.</div>;

	// Проверка наличия следующих серий (с датой выхода в будущем)
	const nextEpisode = episodes.find(
		episode => episode && new Date(episode.air_date) > new Date()
	);

	// Если нет будущих серий, не отображаем блок серий
	if (!nextEpisode) {
		return null;
	}

	const getDaysText = days => {
		if (days % 10 === 1 && days % 100 !== 11) {
			return 'день';
		} else if (
			days % 10 >= 2 &&
			days % 10 <= 4 &&
			(days % 100 < 10 || days % 100 >= 20)
		) {
			return 'дня';
		} else {
			return 'дней';
		}
	};

	const getDaysUntilNextEpisode = nextAirDate => {
		const currentDate = new Date();
		const nextDate = new Date(nextAirDate);
		const diffTime = nextDate - currentDate;
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		return diffDays > 0 ? diffDays : 0;
	};

	const daysUntilNext = getDaysUntilNextEpisode(nextEpisode.air_date);
	const daysText = getDaysText(daysUntilNext);

	const getImageUrl = path =>
		path
			? `https://image.tmdb.org/t/p/w300${path}`
			: './images/poster-not-found.png';

	return (
		<div className='mt-4'>
			<button
				onClick={() => setOpen(!open)}
				aria-controls='other-episodes'
				aria-expanded={open}
				className='btn btn-secondary mb-3'
			>
				{open ? 'Свернуть все серии ↑' : 'Даты выхода серий ↓'}
			</button>
			<Collapse in={open}>
				<div id='other-episodes'>
					<ul className='list-unstyled mt-3'>
						{episodes.map(
							episode =>
								episode.id !== nextEpisode?.id && (
									<li
										key={episode.id}
										className='d-flex align-items-center p-2 bg-dark rounded-3 mb-1'
									>
										<div className='flex-shrink-0 me-3'>
											<img
												src={getImageUrl(episode.still_path)}
												alt={episode.name}
												className='episode-image rounded-3'
												width={120}
												height={80}
											/>
										</div>
										<div className='flex-grow-1'>
											<div className='mb-0 fw-bold'>{episode.name}</div>
											<div className='small text-muted fw-medium'>
												{episode.season_number} сезон, {episode.episode_number}{' '}
												серия
											</div>
										</div>
										<div className='me-4 text-end'>
											<div className='small text-muted fw-medium'>
												{episode.air_date || 'Неизвестно'}
											</div>
											{episode.air_date &&
												new Date(episode.air_date) <= new Date() && (
													<span>Вышла ✅</span>
												)}
										</div>
									</li>
								)
						)}
					</ul>
				</div>
			</Collapse>
			<ul className='list-unstyled bg-dark p-2 rounded-3 m-0'>
				<li key={nextEpisode.id} className='d-flex align-items-center'>
					<div className='flex-shrink-0 me-3'>
						<img
							src={getImageUrl(nextEpisode.still_path)}
							alt={nextEpisode.name}
							className='episode-image rounded-3'
							width={120}
							height={80}
						/>
					</div>
					<div className='flex-grow-1'>
						<div className='mb-0 fw-bold'>{nextEpisode.name}</div>
						<div className='small text-muted fw-medium'>
							{nextEpisode.season_number} сезон, {nextEpisode.episode_number}{' '}
							серия
						</div>
					</div>
					<div className='me-4 text-end'>
						<div className='mb-0 fw-bold'>
							Через {daysUntilNext} {daysText}
						</div>
						<div className='small text-muted fw-medium'>
							{nextEpisode.air_date || 'Неизвестно'}
						</div>
					</div>
				</li>
			</ul>
		</div>
	);
};

export default EpisodeList;

import React, { useEffect } from 'react';
import {
	Route,
	BrowserRouter as Router, // Измените здесь
	Routes,
	useLocation,
} from 'react-router-dom';
import Header from './components/Header';
import MovieDetails from './components/movie-details/MovieDetails';
import TitleUpdater from './components/TitleUpdater';
import ActorPage from './pages/ActorPage';
import CatalogMovies from './pages/CatalogMovies';
import CatalogTVShows from './pages/CatalogTVShows';
import Home from './pages/Home';
import LoginPage from './pages/LoginPage';
import UserPage from './pages/UserPage';

// Компонент для прокрутки вверх при смене маршрута
const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		// Прокрутка в верхнюю часть страницы
		window.scrollTo(0, 0);
	}, [pathname]); // Запускаем при изменении маршрута

	return null; // Не рендерим ничего
};

const App = () => {
	return (
		<Router>
			<Header />
			<TitleUpdater />
			<ScrollToTop />
			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/movie/:id' element={<MovieDetails type='movie' />} />
				<Route path='/tv/:id' element={<MovieDetails type='tv' />} />
				<Route path='/movies' element={<CatalogMovies />} />
				<Route path='/tv-shows' element={<CatalogTVShows />} />
				<Route path='/user' element={<UserPage />} />
				<Route path='/login' element={<LoginPage />} />
				<Route path='/genre/movie/:genreId' element={<CatalogMovies />} />
				<Route path='/genre/tv/:genreId' element={<CatalogTVShows />} />
				<Route path='/actor/:id' element={<ActorPage />} />
			</Routes>
		</Router>
	);
};

export default App;

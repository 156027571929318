import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TitleUpdater = () => {
	const location = useLocation();

	useEffect(() => {
		// Функция для обновления заголовка страницы
		const updateTitle = () => {
			const h1 = document.querySelector('h1');
			if (h1) {
				document.title = h1.innerText; // Устанавливаем title из <h1>
			} else {
				document.title = 'Каталог Фильмов и ТВ'; // Если нет <h1>, используем заголовок по умолчанию
			}
		};

		// Попробуем сначала установить заголовок с небольшой задержкой для асинхронных компонентов
		const timeoutId = setTimeout(updateTitle, 100); // 100 мс для задержки

		// Используем MutationObserver для отслеживания изменений в DOM
		const observer = new MutationObserver(() => {
			updateTitle(); // Обновляем title при любых изменениях в DOM
		});

		// Наблюдаем за изменениями в теле документа
		observer.observe(document.body, { childList: true, subtree: true });

		return () => {
			clearTimeout(timeoutId); // Очищаем таймаут при размонтировании
			observer.disconnect(); // Останавливаем наблюдение
		};
	}, [location]);

	return null; // Этот компонент не рендерит ничего на экран
};

export default TitleUpdater;

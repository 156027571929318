import React, { useEffect, useState } from 'react';
import MovieCard from '../components/MovieCard'; // Импортируйте ваш компонент MovieCard

const API_KEY = '8c25628d17af8bc23ca51c3561b5b636';

const UserPage = () => {
	const [movieBookmarks, setMovieBookmarks] = useState([]); // Храним закладки фильмов
	const [tvBookmarks, setTvBookmarks] = useState([]); // Храним закладки сериалов
	const [filteredBookmarks, setFilteredBookmarks] = useState([]);
	const [error, setError] = useState('');
	const [accountId, setAccountId] = useState(null);
	const [mediaType, setMediaType] = useState('all'); // Начальное состояние для всех типов
	const sessionId = localStorage.getItem('tmdb_session_id');

	useEffect(() => {
		const fetchAccountId = async () => {
			if (!sessionId) {
				setError('Вы не авторизованы. Пожалуйста, войдите в систему.');
				return;
			}

			try {
				const response = await fetch(
					`https://api.themoviedb.org/3/account?api_key=${API_KEY}&session_id=${sessionId}`
				);
				const data = await response.json();
				if (response.ok && data.id) {
					setAccountId(data.id);
				} else {
					setError('Не удалось получить информацию о пользователе.');
				}
			} catch (err) {
				console.error('Ошибка при получении account ID:', err);
				setError('Ошибка при получении информации о пользователе.');
			}
		};

		fetchAccountId();
	}, [sessionId]);

	useEffect(() => {
		const fetchBookmarks = async () => {
			if (!accountId) return;

			try {
				const [moviesResponse, tvResponse] = await Promise.all([
					fetch(
						`https://api.themoviedb.org/3/account/${accountId}/favorite/movies?api_key=${API_KEY}&session_id=${sessionId}&language=ru-RU`
					),
					fetch(
						`https://api.themoviedb.org/3/account/${accountId}/favorite/tv?api_key=${API_KEY}&session_id=${sessionId}&language=ru-RU`
					),
				]);

				if (moviesResponse.ok && tvResponse.ok) {
					const moviesData = await moviesResponse.json();
					const tvData = await tvResponse.json();

					// Сохраняем закладки фильмов и сериалов в отдельные состояния
					setMovieBookmarks(moviesData.results);
					setTvBookmarks(tvData.results);

					// Устанавливаем начальные фильтры
					setFilteredBookmarks(
						sortBookmarksByCreatedAt([...moviesData.results, ...tvData.results])
					);
				} else {
					setError('Не удалось загрузить закладки.');
				}
			} catch (err) {
				console.error('Ошибка при загрузке закладок:', err);
				setError('Произошла ошибка при загрузке закладок.');
			}
		};

		fetchBookmarks();
	}, [accountId, sessionId]);

	const sortBookmarksByCreatedAt = bookmarksToSort => {
		return [...bookmarksToSort].sort(
			(a, b) => new Date(b.created_at) - new Date(a.created_at)
		); // Избегаем мутации исходного массива
	};

	const filterBookmarks = type => {
		setMediaType(type);
		let filtered = [];

		if (type === 'movie') {
			filtered = movieBookmarks;
		} else if (type === 'tv') {
			filtered = tvBookmarks;
		} else {
			filtered = [...movieBookmarks, ...tvBookmarks];
		}

		// Сортируем по дате добавления
		setFilteredBookmarks(sortBookmarksByCreatedAt(filtered));
	};

	return (
		<div className='container mt-5'>
			<h1 className='h2'>Мои закладки</h1>
			<div className='d-flex gap-2 my-3'>
				<button
					className={`btn btn-secondary ${mediaType === 'all' ? 'active' : ''}`}
					onClick={() => filterBookmarks('all')}
				>
					Все
				</button>
				<button
					className={`btn btn-secondary ${mediaType === 'movie' ? 'active' : ''}`}
					onClick={() => filterBookmarks('movie')}
				>
					Фильмы
				</button>
				<button
					className={`btn btn-secondary ${mediaType === 'tv' ? 'active' : ''}`}
					onClick={() => filterBookmarks('tv')}
				>
					Сериалы
				</button>
			</div>
			{error && <div className='alert alert-danger'>{error}</div>}
			{filteredBookmarks.length > 0 ? (
				<div className='row'>
					{filteredBookmarks.map(bookmark => (
						<MovieCard key={bookmark.id} movie={bookmark} />
					))}
				</div>
			) : (
				<p>У вас нет закладок.</p>
			)}
		</div>
	);
};

export default UserPage;

import React from 'react';
import { Link } from 'react-router-dom';

const getEmoji = rating => {
	if (rating >= 8) return '🌟';
	if (rating >= 7) return '🎉';
	if (rating >= 6) return '😊';
	if (rating >= 5) return '😐';
	if (rating >= 4) return '😕';
	if (rating >= 3) return '😢';
	return '💩';
};

const MovieCard = ({ movie }) => {
	if (!movie) {
		return <div className='card'>Нет данных для отображения</div>;
	}

	const mediaType = movie.media_type || (movie.release_date ? 'movie' : 'tv');

	return (
		<div className='col-6 col-sm-3 col-xl-2 p-2 pb-3'>
			<Link
				to={`/${mediaType}/${movie.id}`}
				className='card card-movie border-0'
			>
				<div className='card-image mb-2'>
					<img
						src={`https://image.tmdb.org/t/p/w300${movie.poster_path}`}
						className='card-img-top'
						alt={movie.title || movie.name || 'Нет названия'}
					/>
				</div>
				<div className='card-body p-0'>
					<h5 className='card-title'>
						{movie.title || movie.name || 'Нет названия'}
					</h5>
					<div className='d-flex gap-2 small fw-medium text-muted'>
						{movie.vote_average > 0 && (
							<span className='text-warning fw-medium'>
								{getEmoji(movie.vote_average)} {movie.vote_average.toFixed(1)}
							</span>
						)}
						<span>
							{movie.release_date?.split('-')[0] ||
								movie.first_air_date?.split('-')[0] ||
								'Нет года'}
						</span>
					</div>
					<span className='movie-badge badge bg-light text-dark'>
						{mediaType === 'movie' ? 'Фильм' : 'Сериал'}
					</span>
				</div>
			</Link>
		</div>
	);
};

export default MovieCard;

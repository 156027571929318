import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MovieCard from '../components/MovieCard'; // Предполагается, что у вас есть компонент MovieCard для отображения фильмов

const API_KEY = '8c25628d17af8bc23ca51c3561b5b636';

const ActorPage = () => {
	const { id } = useParams(); // Получаем ID актера из параметров URL
	const [actor, setActor] = useState(null);
	const [filmography, setFilmography] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [showFullBiography, setShowFullBiography] = useState(false); // Состояние для показа полной биографии

	useEffect(() => {
		const fetchActorData = async () => {
			try {
				const actorResponse = await axios.get(
					`https://api.themoviedb.org/3/person/${id}?api_key=${API_KEY}&language=ru`
				);
				const filmographyResponse = await axios.get(
					`https://api.themoviedb.org/3/person/${id}/movie_credits?api_key=${API_KEY}&language=ру`
				);

				setActor(actorResponse.data);
				setFilmography(filmographyResponse.data.cast || []); // Получаем только роли в фильмах
			} catch (error) {
				setError('Ошибка при загрузке данных об актере. Попробуйте еще раз.');
				console.error('Ошибка при загрузке данных об актере:', error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchActorData();
	}, [id]);

	if (isLoading) return <div className='text-center'>Загрузка...</div>;
	if (error) return <div className='alert alert-danger'>{error}</div>;

	const formatBiography = biography => {
		// Разделение биографии на абзацы
		return biography
			.split('\n')
			.map((paragraph, index) => <p key={index}>{paragraph.trim()}</p>);
	};

	const formatDate = dateString => {
		if (!dateString) return 'Не указано';
		const date = new Date(dateString);
		const options = { day: 'numeric', month: 'long', year: 'numeric' };
		const formattedDate = date.toLocaleDateString('ru-RU', options);
		return `${formattedDate} года`;
	};

	const biographyParagraphs = actor.biography
		? formatBiography(actor.biography)
		: [];

	return (
		<div className='container'>
			{actor && (
				<div className='mb-4'>
					<div className='row mb-4'>
						<div className='col-4 col-md-3 position-relative'>
							<div className='backrop-image'>
								<img
									className='img-fluid'
									src={`https://image.tmdb.org/t/p/w300${actor.profile_path}`}
									alt='Backdrop'
								/>
							</div>
							<img
								src={`https://image.tmdb.org/t/p/w500${actor.profile_path}`}
								alt={actor.name}
								className='img-fluid rounded'
							/>
						</div>
						<div className='col-8 col-md'>
							<h1 className='display-5 fw-bold'>{actor.name}</h1>
							<div>
								<p>
									<strong>Карьера:</strong>{' '}
									{actor.known_for_department || 'Не указано'}
								</p>
								<p>
									<strong>Дата рождения:</strong> {formatDate(actor.birthday)}
								</p>
								<p>
									<strong>Место рождения:</strong>{' '}
									{actor.place_of_birth || 'Не указано'}
								</p>
								<p>
									<strong>Рост:</strong>{' '}
									{actor.height ? `${actor.height / 100} м` : 'Не указано'}
								</p>
							</div>
							<div>
								<h4>Биография:</h4>
								{biographyParagraphs.slice(
									0,
									showFullBiography ? biographyParagraphs.length : 4
								)}
								{!showFullBiography && biographyParagraphs.length > 4 && (
									<a
										href='#'
										onClick={e => {
											e.preventDefault();
											setShowFullBiography(true);
										}}
									>
										<span className='text-primary'>...Развернуть</span>
									</a>
								)}
							</div>
						</div>
					</div>

					<h3>Фильмография:</h3>
					<div className='row'>
						{filmography.length > 0 ? (
							filmography.map(movie => <MovieCard movie={movie} />)
						) : (
							<p>Актер не снимался в фильмах или сериалах.</p>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default ActorPage;

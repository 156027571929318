import React from 'react';

const FavoriteButton = ({ isFavorite, onAdd, onRemove }) => {
	return (
		<button
			className={`btn ${isFavorite ? 'btn-warning' : 'btn-secondary'} w-100`}
			onClick={isFavorite ? onRemove : onAdd}
		>
			{isFavorite ? 'В закладках' : 'В закладки'}
		</button>
	);
};

export default FavoriteButton;

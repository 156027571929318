import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MovieCard from '../MovieCard';
import Skeleton from '../Skeleton';
import EpisodeList from './EpisodeList';
import MovieOverview from './MovieOverview';
import Player from './Player';
import ToastNotification from './ToastNotification';

const API_KEY = '8c25628d17af8bc23ca51c3561b5b636';
const CACHE = {};

const MovieDetails = ({ type }) => {
	const [isPlayerVisible, setIsPlayerVisible] = useState(true);
	const [item, setItem] = useState(null);
	const [credits, setCredits] = useState(null);
	const [recommendations, setRecommendations] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState('');
	const [isFavorite, setIsFavorite] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [toastMessage, setToastMessage] = useState('');
	const [isVisible, setIsVisible] = useState(false);
	const sessionId = localStorage.getItem('tmdb_session_id');
	const { id } = useParams();

	const showNotification = message => {
		setToastMessage(message);
		setShowToast(true);
		setTimeout(() => setShowToast(false), 3000);
	};

	const fetchData = useCallback(async () => {
		setIsLoading(true);
		if (CACHE[id]) {
			const { item, credits, isFavorite } = CACHE[id];
			setItem(item);
			setCredits(credits);
			setIsFavorite(isFavorite);
			setIsLoading(false);
			setIsVisible(true);
			return;
		}

		try {
			const [itemData, creditsData, recommendationsData] = await Promise.all([
				fetch(
					`https://api.themoviedb.org/3/${type}/${id}?api_key=${API_KEY}&language=ru`
				),
				fetch(
					`https://api.themoviedb.org/3/${type}/${id}/credits?api_key=${API_KEY}&language=ru`
				),
				fetch(
					`https://api.themoviedb.org/3/${type}/${id}/recommendations?api_key=${API_KEY}&language=ru&page=1`
				),
			]);

			const [itemResult, creditsResult, recommendationsResult] =
				await Promise.all([
					itemData.json(),
					creditsData.json(),
					recommendationsData.json(),
				]);

			setItem(itemResult);
			setCredits(creditsResult);
			setRecommendations(recommendationsResult.results || []);

			CACHE[id] = {
				item: itemResult,
				credits: creditsResult,
				isFavorite: false,
			};
		} catch (error) {
			setError('Не удалось загрузить данные.');
			console.error('Ошибка:', error);
		} finally {
			setIsLoading(false);
			setIsVisible(true);
		}
	}, [id, type]);

	const checkIfFavorite = useCallback(async () => {
		if (!sessionId) return;
		try {
			const accountIdResponse = await fetch(
				`https://api.themoviedb.org/3/account?api_key=${API_KEY}&session_id=${sessionId}`
			);
			const accountId = accountIdResponse.ok
				? await accountIdResponse.json()
				: null;

			if (!accountId) return;

			const favoriteResponse = await fetch(
				`https://api.themoviedb.org/3/account/${accountId}/favorite/${type}?api_key=${API_KEY}&session_id=${sessionId}`
			);
			const favoriteData = await favoriteResponse.json();

			setIsFavorite(
				favoriteData.results?.some(item => item.id === parseInt(id)) || false
			);
		} catch (error) {
			console.error('Ошибка при проверке закладок:', error);
		}
	}, [id, type, sessionId]);

	const handleFavoriteToggle = async favorite => {
		if (!sessionId) {
			showNotification(
				`Вы должны войти в систему, чтобы ${favorite ? 'добавить' : 'удалить'} из закладок.`
			);
			return;
		}

		const accountIdResponse = await fetch(
			`https://api.themoviedb.org/3/account?api_key=${API_KEY}&session_id=${sessionId}`
		);
		const accountId = accountIdResponse.ok
			? await accountIdResponse.json()
			: null;

		if (!accountId) return;

		try {
			const response = await fetch(
				`https://api.themoviedb.org/3/account/${accountId}/favorite?api_key=${API_KEY}&session_id=${sessionId}`,
				{
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ media_type: type, media_id: id, favorite }),
				}
			);
			const data = await response.json();

			if (data.success) {
				setIsFavorite(favorite);
				showNotification(
					`Фильм ${favorite ? 'добавлен' : 'удален'} из закладок!`
				);
				CACHE[id].isFavorite = favorite;
			} else {
				showNotification(
					`Не удалось ${favorite ? 'добавить' : 'удалить'} фильм в закладки.`
				);
			}
		} catch (error) {
			console.error(
				`Ошибка при ${favorite ? 'добавлении' : 'удалении'} в закладки:`,
				error
			);
			showNotification('Произошла ошибка. Попробуйте еще раз.');
		}
	};

	const formatReleaseDate = dateString => {
		return new Date(dateString).toLocaleDateString('ru-RU', {
			day: 'numeric',
			month: 'long',
			year: 'numeric',
		});
	};

	useEffect(() => {
		const loadData = async () => {
			await fetchData();
			await checkIfFavorite();
		};

		loadData();

		return () => {
			setIsLoading(false); // Обнуляем состояние загрузки при размонтировании
		};
	}, [fetchData, checkIfFavorite]);

	if (isLoading)
		return (
			<Skeleton
				style={{ opacity: isLoading ? 1 : 0, transition: 'opacity 0.5s ease' }}
			/>
		);

	if (!item || !credits) return <div>{error || 'Информация не найдена.'}</div>;

	return (
		<div className='container mb-5'>
			<ToastNotification
				showToast={showToast}
				toastMessage={toastMessage}
				onClose={() => setShowToast(false)}
			/>
			<div
				style={{
					opacity: isVisible ? 1 : 0,
					transition: 'opacity 0.5s ease-in-out',
				}}
			>
				<MovieOverview
					item={item}
					credits={credits}
					formatReleaseDate={formatReleaseDate}
					isFavorite={isFavorite}
					handleAddToFavorites={() => handleFavoriteToggle(true)}
					handleRemoveFromFavorites={() => handleFavoriteToggle(false)}
					type={type}
					handleShowPlayer={() => setIsPlayerVisible(true)}
				/>
				{isPlayerVisible && <Player id={id} isVisible={isPlayerVisible} />}
				{type === 'tv' && <EpisodeList showId={id} />}
				<div className='recommendations-section'>
					<h3 className='mb-3 mt-4'>Рекомендации</h3>
					<div className='row'>
						{recommendations.map(recommendation => (
							<MovieCard key={recommendation.id} movie={recommendation} />
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default MovieDetails;

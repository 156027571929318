import React, { useEffect, useState } from 'react';

const Player = ({ id, isVisible }) => {
	const [title, setTitle] = useState(''); // состояние для названия
	const [hasError, setHasError] = useState(false); // состояние для ошибки

	useEffect(() => {
		if (!isVisible) return; // Не загружать плеер, если он не видим

		// Получаем название фильма из тегов на странице
		const movieTitle = document.querySelector('.movie-title')?.innerText || '';
		const originalTitle =
			document.querySelector('.movie-title-original')?.innerText || '';

		// Логируем названия
		console.log('Найдено название фильма:', movieTitle);
		console.log('Найдено оригинальное название фильма:', originalTitle);

		// Устанавливаем название для плеера
		setTitle(originalTitle || '');

		// Добавляем скрипт kinobox.min.js в документ
		const script = document.createElement('script');
		script.src = 'https://kinobox.tv/kinobox.min.js';
		script.async = true;

		// Обработка загрузки скрипта
		script.onload = () => {
			const kinoboxPlayer = document.querySelector('.kinobox_player');
			if (kinoboxPlayer) {
				// Используем kbox для инициализации плеера с настройками меню
				try {
					window.kbox(kinoboxPlayer, {
						search: {
							tmdb: id,
						},
						menu: {
							enable: false,
						},
						players: {
							alloha: {
								enable: true,
								position: 1,
								domain: 'https://sansa.newplayjj.com:9443',
							},
						},
						params: {
							alloha: { token: '3a4e69a3bb3a0eb3b5bf5eba7e563b' },
						},
					});
					console.log(
						'Плеер инициализирован с ID:',
						id,
						'и названием:',
						movieTitle || originalTitle
					);
				} catch (error) {
					console.error('Ошибка инициализации плеера:', error);
					setHasError(true); // Устанавливаем состояние ошибки
				}
			}
		};

		// Обработка ошибок загрузки скрипта
		script.onerror = error => {
			console.error('Ошибка загрузки скрипта:', error);
			setHasError(true); // Устанавливаем состояние ошибки
		};

		// Функция для очистки скрипта при размонтировании
		const cleanUp = () => {
			if (script.parentNode) {
				script.parentNode.removeChild(script);
			}
		};

		document.body.appendChild(script);
		return cleanUp;
	}, [id, isVisible]); // Запускаем только когда isVisible изменяется

	if (hasError) {
		return (
			<div>
				Произошла ошибка при загрузке плеера. Пожалуйста, попробуйте позже.
			</div>
		);
	}

	if (!isVisible) {
		return null; // Не рендерим плеер, пока не готовы
	}

	return <div className='kinobox_player my-4 rounded-3'></div>;
};

export default Player;

// MovieOverviewSkeleton.js
import React from 'react';
import '../Skeleton.css'; // Импортируйте ваши стили для скелетона

const MovieOverviewSkeleton = () => {
	return (
		<div className='container mb-5'>
			<div className='row mb-4'>
				<div className='col-12 col-sm-4 col-md-3 mb-4 mb-md-0'>
					<div className='skeleton rounded-3 poster'></div>
					<div className='skeleton favbutton'></div>
				</div>
				<div className='col-12 col-sm'>
					<div className='mb-2'>
						<div className='skeleton title'></div>
						<div className='skeleton tagline'></div>
					</div>
					<ul className='movie-details list-unstyled'>
						<li className='pb-3'>
							<div className='skeleton overview'></div>
						</li>
						<li>
							<div className='skeleton rating'></div>
						</li>
						<li>
							<div className='skeleton premiere'></div>
						</li>
						<li>
							<div className='skeleton genres'></div>
						</li>
						<li>
							<div className='skeleton cast'></div>
						</li>
						<li>
							<div className='skeleton director'></div>
						</li>
						<li>
							<div className='skeleton runtime'></div>
						</li>
						<li>
							<div className='skeleton status'></div>
						</li>
					</ul>
				</div>
				<div className='skeleton scelet-player-container'></div>
			</div>
		</div>
	);
};

export default MovieOverviewSkeleton;
